import React, { Component } from "react";
import PropTypes from "prop-types";
import IframeComm from "react-iframe-comm";
import Services from "../../../services/Services";
import { URL_BASE, URL_SIM_MAP } from "../../../utils/globals";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SelectModal from "./SelectModal";
import FreeAssignment from "../FreeAssignment";
import Axios from "axios";
import resources from '../../../translations.json';
import { NewClock } from "../../../utils/iconsSvg";

const RSwal = withReactContent(Swal);
class SeatsMap extends Component {
    state = {
        attributes: {
            src: "",
            width: "100%",
            height: "0",
            frameBorder: 0,
        },
        postMessageData: "",
    };
    componentDidMount() {
        const { selectedFunction, dolarToday, MonedaPorDefecto } = this.props;
        //console.log("componentDidMount---->REACT_APP_IS_DEV",process.env.REACT_APP_IS_DEV,"UsaNuevoMapa",selectedFunction.UsaNuevoMapa)
        const urlMapa = /*selectedFunction.UsaNuevoMapa
            ? */ `https://ticketmundo.sfo2.cdn.digitaloceanspaces.com/SimPPMap.html?minimap=0&rsv_srv=1&api_sub=apisgb-clone&dev=${
            process.env.REACT_APP_IS_DEV
        }&id=${selectedFunction.FuncionId}&distribucion=${
            selectedFunction.MapaSvgId
        }&lg=${this.props.language}${
            selectedFunction.Iso === "VES"
                ? MonedaPorDefecto === "USD"
                    ? `&conv=${dolarToday}`
                    : ""
                : ""
        }`;
        //    /* : `${URL_BASE}/Pages/mapasWep.aspx?id=${
        //           selectedFunction.FuncionId
        //       }&distribucion=${selectedFunction.MapaSvgId}&lg=${
        //           this.props.language
        //       }${
        //           selectedFunction.Iso === "VES"
        //               ? MonedaPorDefecto === "USD"
        //                   ? `&conv=${dolarToday}`
        //                   : ""
        //               : ""
        //       }`*/;
        this.setState({
            attributes: {
                height:
                    document.getElementsByTagName("body")[0].clientHeight - 200,
                src: urlMapa,
            },
        });

        // window.addEventListener("beforeunload", this.onBeforeUnload);
    }

    onBeforeUnload(e) {
        if (true) {
            e.preventDefault();
            e.returnValue = "si actualiza el navegador perderá las reservas hechas hasta ahora";
            return "si actualiza el navegador perderá las reservas hechas hasta ahora";
        }
    }

    componentDidUpdate(prevProps) {
        const { selectedFunction, dolarToday, MonedaPorDefecto } = this.props;
        //console.log("componentDidUpdate---->REACT_APP_IS_DEV",process.env.REACT_APP_IS_DEV,"UsaNuevoMapa",selectedFunction.UsaNuevoMapa)
        // "https://ticketmundo.sfo2.cdn.digitaloceanspaces.com/SimPPMap.html?dev=1&id=5595&distribucion=85&lg=es&conv=32.3&tw=1&rsv_srv=1"
        const urlMapa = /*selectedFunction.UsaNuevoMapa
            ?*/ `https://ticketmundo.sfo2.cdn.digitaloceanspaces.com/SimPPMap.html?minimap=0&rsv_srv=1&api_sub=apisgb-clone&dev=${
            process.env.REACT_APP_IS_DEV
        }&id=${selectedFunction.FuncionId}&distribucion=${
            selectedFunction.MapaSvgId
        }&lg=${this.props.language}${
            selectedFunction.Iso === "VES"
                ? MonedaPorDefecto === "USD"
                    ? `&conv=${dolarToday}`
                    : ""
                : ""
        }`;
        // /*: `${URL_BASE}/Pages/mapasWep.aspx?id=${
        //       selectedFunction.FuncionId
        //   }&distribucion=${selectedFunction.MapaSvgId}&lg=${
        //       this.props.language
        //   }${
        //       selectedFunction.Iso === "VES"
        //           ? MonedaPorDefecto === "USD"
        //               ? `&conv=${dolarToday}`
        //               : ""
        //           : ""
        //   }`*/;

        if (MonedaPorDefecto !== prevProps.MonedaPorDefecto) {
            this.setState({
                attributes: {
                    height:
                        document.getElementsByTagName("body")[0].clientHeight -
                        200,
                    src: urlMapa,
                },
            });
        }
    }

    onReceiveMessage = async (response) => {
        const { selectedFunction, global } = this.props;
        const { data: dataRes } = response;

        const { showID, cancelReservation, items } = this.props;
        let ticketSelected = null;
        let makeReservation = async (puestowebId, tipoentradaId, funcionId) => {
            const { data, status } = await Services.reservations
                .createReservation({
                    puestowebId,
                    tipoentradaId,
                    funcionId,
                })
                .catch((error) =>
                    error.response
                        ? error.response
                        : { data: null, status: 500 }
                );
                console.log(data)
            if (status === 200) {
                this.props.setReservation({
                    ...data,
                    funcionId: this.props.showID,
                    ShapeId: dataRes.ShapeId,
                });
                this.props.handleSelectTicket();
                this.props.setLoader(-1);
                if (
                    this.props.selectedFunction.CantidadMaximaEntradasCanales +
                        1 ===
                    this.props.items.length
                ) {
                    if (typeof this.props.middleWareSeat === "function") {
                        this.props.middleWareSeat();
                        let itemToDelete = items.filter(
                            (i) => i.ShapeId === dataRes.ShapeId
                        );
                        cancelReservation({
                            ...data,
                            ShapeId: dataRes.ShapeId,
                        });
                        return;
                    }
                }
            } else {
                this.props.setLoader(-1);
                let Sw = RSwal.fire({
                    html: (
                        <ModalReservation
                            callbackClose={() => {
                                RSwal.close();
                            }}
                        />
                    ),
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    customClass: {
                        popup: "swPopupCus",
                    },
                });
            }
        };

        // console.log(dataRes);
        console.log(dataRes && dataRes.Tipo)
        if (dataRes.Tipo === "ReservadoPorMapa") {
            dataRes.Puestos.forEach(async (element) => {
                if (
                    this.props.reservationSeats.findIndex(
                        (r) =>
                            r.ShapeId === Number(element.SeatId) &&
                            selectedFunction.FuncionId === r.funcionId
                    ) < 0
                ) {
                    const { data: tipoEntrada, status } =
                        await Services.reservations.getByZoneSectionShow(
                            selectedFunction.FuncionId,
                            Number(element.SeatId)
                        );
                    if(tipoEntrada.length === 0 ){
                        RSwal.fire({
                            customClass: {
                                popup: "modalTipoEntradaError",
                            },
                            showConfirmButton: false,
                            showCloseButton: true,
                            html: (<div>
                                <p>Error al seleccionar entrada, por favor intente de nuevo.</p>
                            </div>),
                        });
                        const itemToDelete = this.props.reservationSeats.find(
                            (r) => r.ShapeId === Number(element.SeatId)
                        );
                        cancelReservation(itemToDelete)
                        return
                    }
                    
                    console.log('tipoEntrada',tipoEntrada)
                    if (tipoEntrada.length === 1 && status === 200) {
                        this.props.setReservation({
                            ...tipoEntrada[0],
                            PuestoNumero: element.SeatNumber,
                            PuestoFila: element.RowNumber,
                            funcionId: selectedFunction.FuncionId,
                            ShapeId: Number(element.SeatId),
                            bloqueado: true,
                        });
                        this.props.handleSelectTicket();
                        this.props.setLoader(-1);
                    }
                    if (tipoEntrada.length > 1 && status === 200) {
                        
                        if(element.IsSelection === true){
                            let Sw = RSwal.fire({
                                html: (
                                    <SelectModal
                                        entradas={tipoEntrada}
                                        global={global}
                                        Simbolo={selectedFunction.MonedaSimbolo}
                                        Iso={selectedFunction.Iso}
                                        onSelect={(ent) => {
                                            this.props.setReservation({
                                                ...ent,
                                                PuestoNumero: element.SeatNumber,
                                                PuestoFila: element.RowNumber,
                                                funcionId: selectedFunction.FuncionId,
                                                ShapeId: Number(element.SeatId),
                                                bloqueado: true,
                                            })
                                            RSwal.clickConfirm();
                                        }}
                                    />
                                ),
                                showConfirmButton: false,
                                allowOutsideClick: false,
                                customClass: {
                                    popup: "swPopupCus",
                                },
                            });
                        }
                }
                this.props.setLoader(-1);
                }
                if (!element.IsSelection) {
                    const itemToDelete = this.props.reservationSeats.find(
                        (r) => r.ShapeId === Number(element.SeatId)
                    );
                    console.log(itemToDelete)
                    cancelReservation(itemToDelete);
                }
            });
            return false;
        }

        if (dataRes.Tipo === "LibreAsignacion") {
            const { data: Tickets, status: statusTickets } = await Axios.get(
                `${process.env.REACT_APP_URL_API}tipodeentrada/por-zona/${dataRes.ZonaId}`
            );
            RSwal.fire({
                // didOpen: () => {
                //   // `RSwal` is a subclass of `Swal` with all the same instance & static methods
                //   RSwal.showLoading()
                // },
                customClass: {
                    popup: "modalFreeAss",
                },
                showConfirmButton: false,
                showCloseButton: true,
                html: (
                    <FreeAssignment
                        showID={showID}
                        Simbolo={selectedFunction.MonedaSimbolo}
                        Iso={selectedFunction.Iso}
                        handleSelectTicket={this.props.handleSelectTicket}
                        setReservation={this.props.setReservation}
                        setResumeTickets={this.props.setResumeTickets}
                        resumeTickets={this.props.resumeTickets}
                        FreeTickets={Tickets}
                        pay={() => {
                            RSwal.close();
                        }}
                        setTotalMount={this.props.setTotalMount}
                        cantMaxEnt={
                            selectedFunction.CantidadMaximaEntradasCanales
                        }
                        global={this.props.global}
                    />
                ),
            });
            // console.log(dataRes)
            return false;
        }
        if (window.location.origin !== response.origin) {
            if (dataRes.IsSelection === true) {
                this.props.setLoader(1);
                try {
                    // const response = await Services.reservations.getByZoneSectionShow(
                    //   `zone=${data.ZoneName}&section=${data.SectionName}&fnId=${showID}&ConsumidorID=0`
                    // );
                    const response =
                        await Services.reservations.getByZoneSectionShow(
                            selectedFunction.FuncionId,
                            dataRes.SeatId
                        );
                        if(response.data === 0 ){
                            RSwal.fire({
                                customClass: {
                                    popup: "modalTipoEntradaError",
                                },
                                showConfirmButton: false,
                                showCloseButton: true,
                                html: (<div>
                                    <p>Error al seleccionar entrada, por favor intente de nuevo.</p>
                                </div>),
                            });
                            return
                        }
                    if (response.data.length === 1) {
                        ticketSelected = response.data[0];
                        makeReservation(
                            dataRes.SeatId,
                            ticketSelected.TipoEntradaId,
                            selectedFunction.FuncionId
                        );
                    } else if (response.data.length > 1) {
                        // ticketSelected = response.data.reduce((prev, current) =>
                        //   prev.PrecioTotal > current.PrecioTotal ? prev : current
                        // );

                        let Sw = RSwal.fire({
                            html: (
                                <SelectModal
                                    entradas={response.data}
                                    global={global}
                                    Simbolo={selectedFunction.MonedaSimbolo}
                                    Iso={selectedFunction.Iso}
                                    onSelect={(ent) => {
                                        makeReservation(
                                            dataRes.SeatId,
                                            ent.TipoEntradaId,
                                            selectedFunction.FuncionId
                                        );
                                        RSwal.clickConfirm();
                                    }}
                                />
                            ),
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            customClass: {
                                popup: "swPopupCus",
                            },
                        });
                    }
                } catch (error) {
                    console.log(error.toString());
                    this.props.setLoader(-1);
                }
            } else {
                let itemToDelete = items.filter(
                    (i) => i.ShapeId === dataRes.ShapeId
                );
                if (itemToDelete[0] && itemToDelete[0].ShapeId) {
                    cancelReservation(itemToDelete[0]);
                }
            }
        }
        if(dataRes.Tipo === "ErrorDeReserva"){
            if(this.props.items.length === this.props.selectedFunction.CantidadMaximaEntradasCanales){
                let Sw = RSwal.fire({
                    html: (
                        <ModalErrorDeReserva
                            text = { `A Seleccionado el numero maximo de ascientos por compra`}
                            callbackClose={() => {
                                RSwal.close();
                            }}
                        />
                    ),
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    customClass: {
                        popup: "swPopupCus",
                    },
                });
            }
        }
        if(dataRes.Tipo === "ReservaExpirada"){
            if(localStorage?.reservationSeats){
                JSON.parse(localStorage?.reservationSeats).forEach(async (element) => {
                    //console.log("element",element);
                    cancelReservation(element);
                })
            }
            let Sw = RSwal.fire({
                html: (
                    <ModalErrorDeReserva
                        text = { resources[global.Idioma.toLowerCase()]?.translation?.timeAvailableExpired }
                        btn =  {resources[global.Idioma.toLowerCase()]?.translation?.tryAgain.toUpperCase()}
                        icon = { <NewClock />}
                        callbackClose={() => {
                            window.location.reload();
                        }}
                    />
                ),
                showConfirmButton: false,
                allowOutsideClick: false,
                customClass: {
                    popup: "swPopupCus",
                },
            });
            localStorage.removeItem("reservationSeats");
        }
    };

    onReady = () => {
        const { reservationSeats,selectedFunction } = this.props;

       if(reservationSeats.length === 0){
            if( localStorage && localStorage.reservationSeats && localStorage.reservationSeats.length){
                if((selectedFunction?.FuncionId) !== JSON.parse(localStorage?.reservationSeats)[0]?.funcionId){
                    return localStorage.removeItem("reservationSeats");
                }

                JSON.parse(localStorage?.reservationSeats).forEach(async (element) => {
                    //console.log("element",element);
                    //this.props.setReservation({element})
                    this.props.setReservation({
                        ...element
                    });
                })
            }
        }
    };

    render() {
        const { attributes, postMessageData } = this.state;
        const { onReceiveMessage, onReady } = this;
        const { shapeId } = this.props;

        console.log(shapeId, postMessageData );

        return (
            <IframeComm
                attributes={attributes}
                postMessageData={shapeId || postMessageData}
                handleReady={onReady}
                id="iframeSeats"
                handleReceiveMessage={onReceiveMessage}
            />
        );
    }
}

SeatsMap.propTypes = {
    selectedFunction: PropTypes.object,
};

const ModalReservation = ({ callbackClose }) => (
    <div
        className={"modal fade modalAlert in"}
        style={{ display: "block" }}
        id="modalFailure"
        role="dialog"
    >
        <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
        >
            <div className="modal-content">
                <div className="conten-expired clear-fl">
                    <div className="top">
                        <i className="icon-cerrar color-b"></i>
                    </div>
                    <div className="content">
                        <div className="title color-titulos">
                            Error al seleccionar asiento
                        </div>
                        <p className="color-texto-contenido">
                            ¡Ups! este asiento ha sido reservado por otro
                            usuario. Por favor, selecciona otro asiento o
                            refresca de nuevo la página para actualizar el mapa
                        </p>
                        <a
                            href=""
                            onClick={(e) => {
                                e.preventDefault();
                                callbackClose();
                            }}
                            data-dismiss="modal"
                            aria-label="Close"
                            className="color-ppal hover-text-ppal"
                        >
                            Aceptar
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const ModalErrorDeReserva = ({ callbackClose,text,icon }) => (
    <div
        className={"modal fade modalAlert in"}
        style={{ display: "block" }}
        id="modalFailure"
        role="dialog"
    >
        <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
        >
            <div className="modal-content">
                <div className="conten-expired clear-fl">
                    <div className="top">
                        {
                            icon ?
                                icon 
                            :
                                <i className="icon-cerrar color-b"></i>
                        }
                    </div>
                    <div className="content">
                        <div className="title color-titulos">
                            {text}
                        </div>
                        <a
                            href=""
                            onClick={(e) => {
                                e.preventDefault();
                                callbackClose();
                            }}
                            data-dismiss="modal"
                            aria-label="Close"
                            className="color-ppal hover-text-ppal"
                        >
                            Aceptar
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
export default React.memo(SeatsMap);
