import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TimerSmall from './TimerSmall.js';


const mapStateToProps = state => ({
  global: state.global,
  event: state.event.event,
});

const mapDispatchToProps = dispatch => ({

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TimerSmall));