import React, { useState, useEffect, useRef } from "react";
// import { Link } from 'react-router-dom';
import GridProductos from "../components/producto/gridProductos";
import {
    getCategoriasPorComercio,
    getProductosPorCatLimit,
    // getProductosPorQuery,
} from "../services/branch";
import SkeletonProds from "../components/skeletons/grid-prod";
import { useSelector } from "react-redux";
import ModalProd from "../components/producto/modalProd";
// import { useParams } from "react-router-dom";
import resources from '../../../../translations.json';
import $ from "jquery";

const ProductosPorCategoria = () => {
    const global = useSelector((store) => store.global);
    const { selectedFunction } = useSelector((store) => store.event);
    const carStore = useSelector((store) => store.car);
    const [Categorias, setCategorias] = useState([]);
    const [CategoriaSeleccionada, setCategoriaSeleccionada] = useState(null);
    const [FilterCategorias, setFilterCategorias] = useState([]);
    const [ProductosF, setProductosF] = useState(null);
    const [Loading, setLoading] = useState(true);
    let [left, setLeft] = useState(0);
    // let [helabel, sethelabel] = useState($(".topCategorias").clientWidth - $(".filters").clientWidth);
    const controladorTiempo = useRef(null);
    let refCarrusel = useRef();
    let topCategorias = useRef();
    

    useEffect(() => {
        if (selectedFunction) {
            // console.log(selectedFunction);
            getCats();
        }
    }, [selectedFunction]);

    const getCats = async () => {
        let cats = await getCategoriasPorComercio(selectedFunction.DDBranchId);
        setLoading(false);
        // console.log(cats.data.data);
        if (cats.status === 200) {
            setFilterCategorias(cats.data.data);
            setCategorias(cats.data.data);
        }
    };
    useEffect(() => {
        if (CategoriaSeleccionada) {
            setFilterCategorias(
                Categorias.filter((n) => n.id === CategoriaSeleccionada)
            );
        } else {
            setFilterCategorias(Categorias);
        }
    }, [CategoriaSeleccionada]);

    // useEffect(() => {
    //     setCategoriaSeleccionada(Categorias[0].id);
    // }, [Categorias]);

    const inputFilter = (e) => {
        clearTimeout(controladorTiempo.current);
        controladorTiempo.current = setTimeout(() => {
            changeText(e.target.value);
        }, 500);
    };
    const changeText = async (q) => {
        setCategoriaSeleccionada(null);
        // if (q.trim() !== "") {
        //     setLoading(true);
        //     let prodFilters = await getProductosPorQuery(q, shop.branchActive);
        //     setLoading(false);
        //     console.log(prodFilters.data);
        //     setProductosF(prodFilters.data);
        // } else {
        //     setProductosF(null);
        // }
    };
    const moveToLeft = () => {
        let maxPosibleValue = 0;
        let translateValue = parseInt($("#filters").style?.left.split('px')[0],10);
        let step = 120;
        if((translateValue - step) > maxPosibleValue){
            left = translateValue - step
        }else{
            left = maxPosibleValue
        }
        setLeft(left)
    }
    const moveToRight = () => {
        let maxPosibleValue = $(".topCategorias")?.[0].clientWidth - $(".filters")?.[0].clientWidth;
        let translateValue = parseInt($("#filters").style?.left.split('px')[0],10);
        let step = 120;
        if((translateValue - step) > maxPosibleValue){
            left = translateValue - step
        }else{
            left = maxPosibleValue
        }
        setLeft(left)
    }
    const t = resources[global.Idioma.toLowerCase()].translation;
    /* console.log("clientWidth",$(".topCategorias").clientWidth)
    console.log("refCarrusel",$(".filters").clientWidth) */
    return (
        <section id="ListaCategorias" className="">
            <div ref={topCategorias} className="topCategorias d-flex justify-content-between">
              
                {/* <span className={`testlabel`} onClick={()=>moveToLeft()}> {'< '}</span> */}
                <div ref={refCarrusel} id="filters" className="filters" style={{left:`${left}px`}}>
                    <button
                        className={
                            CategoriaSeleccionada === null &&
                            ProductosF === null
                                ? "active"
                                : ""
                        }
                        onClick={() => {
                            setProductosF(null);
                            setCategoriaSeleccionada(null);
                        }}
                    >
                        <span>{t.alls}</span>
                    </button>
                    {Categorias.map((c) => (
                        <button
                            key={c.id}
                            className={
                                CategoriaSeleccionada === c.id ? "active" : ""
                            }
                            onClick={() => {
                                setProductosF(null);
                                setCategoriaSeleccionada(c.id);
                            }}
                        >
                            <span>{c.name} </span>
                        </button>
                    ))}
                </div>
                {/* <span className={`testlabel`} onClick={()=>moveToRight()}> {'> '}</span> */}
                
                {/* <div className="search">
                    <div className="_wrapinput icon-l">
                        <input
                            type="text"
                            placeholder="Buscar Productos"
                            onChange={inputFilter}
                        />
                        <i className="icon-lupa"></i>
                    </div>
                </div> */}
            </div>
            {Loading ? (
                <>
                    <SkeletonProds />
                </>
            ) : (
                <>
                    {ProductosF === null ? (
                        FilterCategorias.map((c, idx) => {
                            return (
                                <Categoria
                                    key={idx}
                                    categoria={c}
                                    branchId={selectedFunction.DDBranchId}
                                />
                            );
                        })
                    ) : (
                        <GridProductos productos={ProductosF} />
                    )}
                </>
            )}
            {carStore.productoActivo && (
                <ModalProd producto={carStore.productoActivo} />
            )}
        </section>
    );
};

export default ProductosPorCategoria;

const Categoria = ({ categoria, branchId }) => {
    const { MonedaPorDefecto } = useSelector((store) => store.global);
    const { selectedFunction } = useSelector((store) => store.event);
    const [Productos, setProductos] = useState([]);
    const [LoadingProds, setLoadingProds] = useState(false);

    let MonedaTienda = selectedFunction.Iso === 'VES' ? MonedaPorDefecto : selectedFunction.Iso

    useEffect(() => {
        (async () => {
            setLoadingProds(true);
            let prods = await getProductosPorCatLimit(
                categoria.id,
                branchId,
                MonedaTienda
            );
            // console.log(prods)
            if (prods.status === 200) {
                setProductos(prods.data.data);
            }
            setLoadingProds(false);
        })();
    }, [categoria]);

    return (
        <div className="categoriaResumen" key={categoria.id}>
            <div className="top d-flex justify-content-between">
                <h2 className="textTitle">{categoria.name}</h2>
                {/* <Link
                    to={categoria.id.toString()}
                    className="d-flex align-items-center"
                >
                    <span>Ver todos</span>
                    <i className="icon-angle-left reflect ml-2"></i>
                </Link> */}
            </div>
            {LoadingProds ? (
                <SkeletonProds />
            ) : (
                <GridProductos productos={Productos} />
            )}
            <div className="mb-3" />
        </div>
    );
};
