import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import configureStore, { history } from "./configureStore";
import App from "./containers/App";
import "./assets/iconos/style.css";
import "moment/locale/es";
import "./i18n";

// import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from "react-ga";

import * as serviceWorker from "./serviceWorker";
import WrapperLogin from "./containers/App/WrapperLogin";
require("dotenv").config();

const trackingId = "UA-159284548-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

history.listen((location) => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
});
ReactDOM.render(
    <Provider store={configureStore()}>
        <ConnectedRouter history={history}>
            <WrapperLogin store={configureStore()}>
                <App />
            </WrapperLogin>
        </ConnectedRouter>
    </Provider>,
    document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
