import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CalendarItem from "./CalendarItem";
import moment from "moment";
import { Link } from "react-router-dom";
import CalendarPicker from "./CalendarPicker";
import Skeleton from "react-loading-skeleton";
import $ from "jquery";
import datepickerFactory from "jquery-datepicker";
import datepickerJAFactory from "jquery-datepicker/i18n/jquery.ui.datepicker-es";
import datepickerJAFactoryGB from "jquery-datepicker/i18n/jquery.ui.datepicker-en-GB";
datepickerFactory($);

const Calendar = (props) => {
    //console.log("calndar", props && props.global && props.global.LabelVenir);
    const [data, setData] = useState({
        toggleList: null,
        funcNameSelected: "",
        selectedDay: "",
        selectedIndexFunction: "",
        selectedFunction: {},
        hideSelectedFunction: false,
    });
    const [hideSelectedFunction, setHideSelectedFunction] = useState(false);
    const { t } = useTranslation();

    const wrapperRef = useRef(null);

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            if (data.toggleList) {
                setData({ ...data, toggleList: !data.toggleList });
                setHideSelectedFunction(false);
            }
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    const onClickHideSelectedFunctionHandle = (value) => {
        setHideSelectedFunction(value);
    };

    const {
        functions,
        selectedFunction,
        hourFormat,
        hourUnit,
        onClickItem,
        isMultiCity,
        isCalendarMultiple,
        isLoaded,
    } = props;
    const daysGB = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const daysES = ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"];

    const formatRoute = () => {
        const funcNameToUrl = (props && props.selectedFunction && props.selectedFunction.EventoNombre).replace(/\s/g,"-").replace('.',"").normalize("NFD").replace(/[#?+&'\u0300-\u036f]/g, "").toLowerCase();
        return funcNameToUrl;
    };

    if (props.language === "EN") {
        datepickerJAFactoryGB($);
    } else {
        datepickerJAFactory($);
    }
    return (
        <div className="calendar-book">
            {functions && functions.length === 1 ? (
                functions.map((func, ind) => {
                    const funcNameToUrl = func.EventoNombre.replace(/\s/g, "-")
                        .normalize("NFD")
                        .replace(/[#?+&'\u0300-\u036f]/g, "")
                        .toLowerCase();
                    selectedFunction.funcNameToUrl = funcNameToUrl;
                    return (
                        <div className="calendar clear-fl unico" key={ind}>
                            <div className="fechas-estandar color-titulos">
                                <div className="item sel">
                                    {moment
                                        .utc(selectedFunction.Fecha)
                                        .format(
                                            "dddd DD MMMM YYYY, " +
                                                (hourFormat === "12hr"
                                                    ? "hh:mm A"
                                                    : "HH:mm")
                                        )}
                                    {hourFormat === "12hr"
                                        ? ""
                                        : ` ${hourUnit}`}
                                </div>
                                <i className={"color-ppal icon-calendar"}></i>
                                {/* <i
                                    className={`icon-angle-d`}
                                    style={
                                        data.toggleList && functions.length > 1
                                            ? { transform: "rotate(180deg)" }
                                            : {}
                                    }
                                ></i> */}
                            </div>
                        </div>
                    );
                })
            ) : !isCalendarMultiple ? (
                <div className="calendar multiple" ref={wrapperRef}>
                    <div className="fechas-estandar color-titulos">
                        <div
                            className="item sel"
                            onClick={() => {
                                setData({
                                    ...data,
                                    toggleList: !data.toggleList,
                                });
                            }}
                        >
                            {selectedFunction
                                ? `${moment
                                      .utc(selectedFunction.Fecha)
                                      .format(
                                          "dddd DD MMMM YYYY, " +
                                              (hourFormat === "12hr"
                                                  ? "hh:mm A"
                                                  : "HH:mm")
                                      )}  ${
                                      hourFormat === "12hr" ? "" : hourUnit
                                  }`
                                : props.global.LabelVenir &&
                                  props.global.LabelVenir.trim() !== ""
                                ? props.global.LabelVenir
                                : t("chooseDateVenue")}
                        </div>
                        {data.toggleList && (
                            <div className="wrap-opciones">
                                {functions &&
                                    functions.map((func) => {
                                        const funcNameToUrl = func.EventoNombre.replace(
                                            /\s/g,
                                            "-"
                                        )
                                            .normalize("NFD")
                                            .replace(
                                                /[#?+&'\u0300-\u036f]/g,
                                                ""
                                            )
                                            .toLowerCase();
                                        func = {
                                            ...func,
                                            funcNameToUrl: funcNameToUrl,
                                        };
                                        return (
                                            <CalendarItem
                                                key={func.FuncionId}
                                                item={func}
                                                hourFormat={hourFormat}
                                                hourUnit={hourUnit}
                                                onClickItem={async () => {
                                                    onClickItem(func);
                                                    setData({
                                                        ...data,
                                                        toggleList: !data.toggleList,
                                                    });
                                                    window.history.pushState(
                                                        null,
                                                        null,
                                                        `${func.EventoId}?selectedFunctionId=${func.FuncionId}`
                                                    );
                                                }}
                                                isMultiCity={isMultiCity}
                                            />
                                        );
                                    })}
                            </div>
                        )}
                    </div>
                    <i className="color-ppal icon-calendar"></i>
                    <i
                        className={`icon-angle-d`}
                        style={
                            data.toggleList && functions.length > 1
                                ? { transform: "rotate(180deg)" }
                                : {}
                        }
                    ></i>
                </div>
            ) : (
                <div className="calendar clear-fl multiple" ref={wrapperRef}>
                    <div className="fechas-estandar color-titulos">
                        {!hideSelectedFunction ? (
                            <div
                                className="item sel"
                                onClick={() => {
                                    setData({
                                        ...data,
                                        toggleList: !data.toggleList,
                                    });
                                }}
                            >
                                {selectedFunction
                                    ? `${moment
                                          .utc(selectedFunction.Fecha)
                                          .format(
                                              "dddd DD MMMM YYYY, " +
                                                  (hourFormat === "12hr"
                                                      ? "hh:mm A"
                                                      : "HH:mm")
                                          )} ${
                                          hourFormat === "12hr" ? "" : hourUnit
                                      }`
                                    : props.global.LabelVenir &&
                                      props.global.LabelVenir.trim() !== ""
                                    ? props.global.LabelVenir
                                    : t("chooseDateVenue")}
                            </div>
                        ) : null}
                        {data.toggleList && (
                            <CalendarPicker
                                functions={functions}
                                hourFormat={hourFormat}
                                hourUnit={hourUnit}
                                languageDays={
                                    props.language === "EN" ? daysGB : daysES
                                }
                                onClickCollapsablePanel={() => {
                                    setData({
                                        ...data,
                                        toggleList: !data.toggleList,
                                    });
                                }}
                                onClickItem={onClickItem}
                                onClickHideSelectedFunction={
                                    onClickHideSelectedFunctionHandle
                                }
                            />
                        )}
                    </div>
                    <i className="color-ppal icon-calendar"></i>
                    <i
                        className={`icon-angle-d`}
                        style={
                            data.toggleList && functions.length > 1
                                ? { transform: "rotate(180deg)" }
                                : {}
                        }
                    ></i>
                </div>
            )}
            {/* <Link
                to={
                    selectedFunction
                        ? `/show/${formatRoute()}/${selectedFunction.FuncionId}`
                        : "#"
                }
            >
                <button
                    className={`button-book btn-ppal ${
                        selectedFunction && !selectedFunction.Agotado
                            ? "active"
                            : ""
                    }`}
                    disabled={selectedFunction && selectedFunction.Agotado}
                >
                    {selectedFunction && selectedFunction.Agotado
                        ? t("soldOut")
                        : t("buyTickets")}
                </button>
            </Link> */}
        </div>
    );
};

export default Calendar;
