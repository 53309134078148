import React, { useEffect,useState, useRef } from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { withTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
    ButtonLogin
} from "@TicketMundo/login-tm-library";
import { useSelector } from "react-redux";
import { UserIcon, PhoneIcon, DniIcon,Pattern,Exclam } from "../../utils/iconsSvg";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Services from "../../services/Services";
import resources from '../../translations.json';


// import axios from "axios";

const PhoneInputField = ({ field, form, ...props }) => {
    return (
        <div className="wrap-phone">
            <PhoneInput
                country={props.cultura.toLowerCase()}
                masks={{
                    cl: ".....-....",
                    us: "...-...-....",
                    ve: "...-...-....",
                    es: "...-...-...",
                }}
                inputProps={{
                    name: field.name,
                    id: props.id,
                }}
                value={field.value}
                onChange={(phoneNumber) => {
                    form.setFieldValue(field.name, phoneNumber);
                    if (field.onChange !== null) {
                        field.onChange(phoneNumber);
                    }
                }}
            />
        </div>
    );
};
const RenderComponent = (props) => {

    if (props.component === "PhoneInput")
        return (
            <Field
                type="tel"
                id={props.id}
                name={props.name}
                component={PhoneInputField}
                cultura={props.cultura}
            />
        );
    else return <></>;
};
const DisplayFormikState = (props) => (
    <div style={{ margin: "1rem 0" }}>
        <h3 style={{ fontFamily: "monospace" }}>Formik State</h3>
        <pre
            style={{
                background: "#f6f8fa",
                fontSize: ".65rem",
                padding: ".5rem",
            }}
        >
            <strong>props</strong> = {JSON.stringify(props, null, 2)}
        </pre>
    </div>
);
const CITIES = [
    [
        "Aysén",
        "Chile Chico",
        "Cisnes",
        "Cochrane",
        "Coyhaique",
        "Guaitecas",
        "Lago Verde",
        "O'Higgins",
        "Río Ibáñez",
        "Tortel",
    ],
    [
        "Antofagasta",
        "Calama",
        "María Elena",
        "Mejillones",
        "Ollagüe",
        "San Pedro de Atacama",
        "Sierra Gorda",
        "Taltal",
        "Tocopilla",
    ],
    ["Arica", "Camarones", "General Lagos", "Putre"],
    [
        "Alto del Carmen",
        "Caldera",
        "Chañaral",
        "Copiapó",
        "Diego de Almagro",
        "Freirina",
        "Huasco",
        "Tierra Amarilla",
        "Vallenar",
    ],
    [
        "Alto Biobío",
        "Antuco",
        "Arauco",
        "Cabrero",
        "Cañete",
        "Chiguayante",
        "Concepción",
        "Contulmo",
        "Coronel",
        "Curanilahue",
        "Florida",
        "Hualpén",
        "Hualqui",
        "Laja",
        "Lebu",
        "Los Alamos",
        "Los Angeles",
        "Lota",
        "Mulchén",
        "Nacimiento",
        "Negrete",
        "Penco",
        "Quilaco",
        "Quilleco",
        "San Pedro de la Paz",
        "San Rosendo",
        "Santa Bárbara",
        "Santa Juana",
        "Talcahuano",
        "Tirúa",
        "Tomé",
        "Tucapel",
        "Yumbel",
    ],
    [
        "Andacollo",
        "Canela",
        "Combarbalá",
        "Coquimbo",
        "Illapel",
        "La Higuera",
        "La Serena",
        "Los Vilos",
        "Monte Patria",
        "Ovalle",
        "Paihuano",
        "Punitaqui",
        "Río Hurtado",
        "Salamanca",
        "Vicuña",
    ],
    [
        "Angol",
        "Carahue",
        "Cholchol",
        "Collipulli",
        "Cunco",
        "Curacautín",
        "Curarrehue",
        "Ercilla",
        "Freire",
        "Galvarino",
        "Gorbea",
        "Lautaro",
        "Loncoche",
        "Lonquimay",
        "Los Sauces",
        "Lumaco",
        "Melipeuco",
        "Nueva Imperia",
        "Padre Las Casas",
        "Perquenco",
        "Pitrufquén",
        "Pucón",
        "Purén",
        "Renaico",
        "Saavedra",
        "Temuco",
        "Teodoro Schmidt",
        "Toltén",
        "Traiguén",
        "Victoria",
        "Vilcún",
        "Villarrica",
    ],
    [
        "Chépica",
        "Chimbarongo",
        "Codegua",
        "Coínco",
        "Coltauco",
        "Doñihue",
        "Graneros",
        "La Estrella",
        "Las Cabras",
        "Litueche",
        "Lolol",
        "Machalí",
        "Malloa",
        "Marchihue",
        "Mostazal",
        "Nancagua",
        "Navidad",
        "Olivar",
        "Palmilla",
        "Paredones",
        "Peralillo",
        "Peumo",
        "Pichidegua",
        "Pichilemu",
        "Placilla",
        "Pumanque",
        "Quinta de Tilcoco",
        "Rancagua",
        "Rengo",
        "Requínoa",
        "San Fernando",
        "Santa Cruz",
        "San Vicente",
    ],
    [
        "Ancud",
        "Calbuco",
        "Castro",
        "Chaitén",
        "Chonchi",
        "Cochamó",
        "Curaco de Vélez",
        "Dalcahue",
        "Fresia",
        "Frutillar",
        "Futaleufú",
        "Hualaihué",
        "Llanquihue",
        "Los Muermos",
        "Maullín",
        "Osorno",
        "Palena",
        "Puerto Montt",
        "Puerto Octay",
        "Puerto Varas",
        "Puqueldón",
        "Purranque",
        "Puyehue",
        "Queilén",
        "Quellón",
        "Quemchi",
        "Quinchao",
        "Río Negro",
        "San Juan de la Costa",
        "San Pablo",
    ],
    [
        "Corral",
        "Futrono",
        "Lago Ranco",
        "Lanco",
        "La Unión",
        "Los Lagos",
        "Máfil",
        "Mariquina",
        "Paillaco",
        "Panguipulli",
        "Río Bueno",
        "Valdivia",
    ],
    [
        "Antártica",
        "Cabo de Hornos",
        "Laguna Blanca",
        "Puerto Natales",
        "Porvenir",
        "Primavera",
        "Punta Arenas",
        "Río Verde",
        "San Gregorio",
        "Timaukel",
        "Torres del Paine",
    ],
    [
        "Cauquenes",
        "Chanco",
        "Colbún",
        "Constitución",
        "Curepto",
        "Curicó",
        "Empedrado",
        "Hualañé",
        "Licantén",
        "Linares",
        "Longaví",
        "Maule",
        "Molina",
        "Parral",
        "Pelarco",
        "Pelluhue",
        "Pencahue",
        "Rauco",
        "Retiro",
        "Río Claro",
        "Romeral",
        "Sagrada Familia",
        "San Clemente",
        "San Javier",
        "San Rafael",
        "Talca",
        "Teno",
        "Vichuquén",
        "Villa Alegre",
        "Yerbas Buenas",
    ],
    [
        "Alhué",
        "Buin",
        "Calera de Tango",
        "Cerrillos",
        "Cerro Navia",
        "Colina",
        "Conchalí",
        "Curacaví",
        "El Bosque",
        "El Monte",
        "Estación Central",
        "Huechuraba",
        "Independencia",
        "Isla de Maipo",
        "La Cisterna",
        "La Florida",
        "La Granja",
        "Lampa",
        "La Pintana",
        "La Reina",
        "Las Condes",
        "Lo Barnechea",
        "Lo Espejo",
        "Lo Prado",
        "Macul",
        "Maipú",
        "María Pinto",
        "Melipilla",
        "Ñuñoa",
        "Padre Hurtado",
        "Paine",
        "Pedro Aguirre Cerda",
        "Peñaflor",
        "Peñalolén",
        "Pirque",
        "Providencia",
        "Pudahuel",
        "Puente Alto",
        "Quilicura",
        "Quinta Normal",
        "Recoleta",
        "Renca",
        "San Bernardo",
        "San Joaquín",
        "San José de Maipo",
        "San Miguel",
        "San Pedro de Melipilla",
        "San Ramón",
        "Santiago",
        "Talagante",
        "Tiltil",
        "Vitacura",
    ],
    [
        "Bulnes",
        "Chillán",
        "Chillán Viejo",
        "Cobquecura",
        "Coelemu",
        "Coihueco",
        "El Carmen",
        "Ninhue",
        "Ñiquén",
        "Pemuco",
        "Pinto",
        "Portezuelo",
        "Quillón",
        "Quirihue",
        "Ránquil",
        "San Carlos",
        "San Fabián",
        "San Ignacio",
        "San Nicolás",
        "Treguaco",
        "Yungay",
    ],
    [
        "Alto Hospicio",
        "Camiña",
        "Colchane",
        "Huara",
        "Iquique",
        "Pica",
        "Pozo Almonte",
    ],
    [
        "Algarrobo",
        "Cabildo",
        "Calera",
        "Calle Larga",
        "Cartagena",
        "Casablanca",
        "Catemu",
        "Concón",
        "El Quisco",
        "El Tabo",
        "Hijuelas",
        "Isla de Pascua",
        "Juan Fernández",
        "La Cruz",
        "La Ligua",
        "Limache",
        "Llaillay",
        "Los Andes",
        "Nogales",
        "Olmué",
        "Panquehue",
        "Papudo",
        "Petorca",
        "Puchuncaví",
        "Putaendo",
        "Quillota",
        "Quilpué",
        "Quintero",
        "Rinconada",
        "San Antonio",
        "San Esteban",
        "San Felipe",
        "Santa María",
        "Santo Domingo",
        "Valparaíso",
        "Villa Alemana",
        "Viña del Mar",
        "Zapallar",
    ],
];

const RenderSelect = ({ id, name, data, defaultText, t }) => {
    return (
        <Field
            name={name}
            id={id}
            render={(props) => {
                const { field } = props;
                const defaultOption = (
                    <option key="default" value="">
                        {t(defaultText)}
                    </option>
                );
                const options = data.map((val, index) => (
                    <option key={index} value={val.id}>
                        {val.text}
                    </option>
                ));
                const selectOptions = [defaultOption, ...options];
                return (
                    <>
                        <select value={field.value} {...field} id={id}>
                            {selectOptions}
                        </select>
                        <i className="icon-angle-d"></i>
                    </>
                );
            }}
        />
    );
};
const RenderSelectCity = ({ id, name, defaultText, t }) => {
    let region = document.getElementsByName("region")[0]?.value || 0;
    // console.log(region);
    return region ? (
        parseInt(region) !== 17 ? (
            <Field
                name={name}
                render={(props) => {
                    const { field } = props;
                    const defaultOption = (
                        <option key="default" value="">
                            Seleccionar Ciudad
                        </option>
                    );
                    const options = CITIES[parseInt(region) - 1].map(
                        (val, index) => (
                            <option key={index} value={val}>
                                {val}
                            </option>
                        )
                    );
                    const selectOptions = [defaultOption, ...options];
                    return (
                        <>
                            <select value={field.value} {...field} id={id}>
                                {selectOptions}
                            </select>
                            <i className="icon-angle-d color-ppal"></i>
                        </>
                    );
                }}
            />
        ) : (
            <Field name={name}>
                {({ field }) => <input id={id} type="text" {...field} />}
            </Field>
        )
    ) : (
        <Field name={name}>
            {({ field }) => <input id={id} type="text" {...field} />}
        </Field>
    );
};
const beneficios = (t) => {
    Swal.fire({
        customClass: {
            title: "titleCode",
        },
        showConfirmButton: false,
        html:`<div class="imgpromo" style="background: url('https://cdn.ticketmundo.live/skin-assets/images/beneficios_images.png');">
                <div>
                    ${t(`seeBenefitTit`)}
                </div>
            </div>
            <div class="textpromo">
                <h2 style="font-size: 22px;">${t(`seeBenefitTit2`)}</h2>
                ${t(`seeBenefitDes`)}
            </div>`,
            customClass: {
            showCloseButton:true,
            popup: 'popuppromoIMage'/*,
            header: '...header',
            title: '...title',
           
            icon: '...icon',
            image: '...image',
            htmlContainer: '...htmlContainer',
            input: '...input',
            inputLabel: '...inputLabel',
            validationMessage: '...validationMessage',
            actions: '...actions',
            confirmButton: '...confirmButton',
            denyButton: '...denyButton',
            cancelButton: '...cancelButton',
            loader: '...loader',
            footer: '....footer',
            timerProgressBar: '....timerProgressBar',*/
            }
    });
};

let initialValues = { nacionalidad: "V" };
const CustomerForm = (props) => {
    let global = useSelector((state) => state.global);
    const { rowsFields, validCounter, validateForm, t, subCultura, title, reservationSeats,detalleRegistro,reservaGratis } = props;
    //console.log('validateForm',validateForm)
    let SESSION = global.userSession ? global.userSession : {}
    if(SESSION?.country_data?.Dni === null ){
        SESSION.country_data.Dni = 'v00000000'
    }
    if(SESSION?.Phone === null ){
        SESSION.Phone = '+584140000000'
    }
   
    const getInitialValues = () => {
        if( SESSION && SESSION.Id){
            //console.log('Dni',SESSION?.country_data?.Dni)
            
            initialValues ={
                cedula: typeof parseInt(SESSION?.country_data?.Dni.substr(0,1)) === 'number' ? `${SESSION?.country_data?.Dni == '1'? 'v00000000': SESSION?.country_data?.Dni}` :SESSION?.country_data?.Dni?.substr(0,1),
                email:SESSION.Email,
                firstName: SESSION.Name,
                lastName:SESSION.LastName,
                nacionalidad: typeof parseInt(SESSION?.country_data?.Dni.substr(0,1)) === 'number' ? 'v' : SESSION?.country_data?.Dni.substr(1),
                phoneNumber: SESSION.Phone
            }
        }
        if(global.userSession){
            rowsFields.forEach((inputs) => {
                inputs.forEach((field) => {
                    if (!initialValues[field.name]) {
                        initialValues[field.name] = field.value || "";
                    }
                });
            });
        }
       
    };
    const render = (errors, touched) => {
        return rowsFields.map((row, index) => {
            return (
                <div key={index}>
                    {row.map((input) => {
                        return (
                            <div
                                key={input.name}
                                className={input.classContainer}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        flex: 1,
                                    }}
                                    className={`wrap-input ${
                                        errors[input.name] &&
                                        touched[input.name]
                                            ? "error"
                                            : ""
                                    }`}
                                >
                                    <label>
                                        {t(input.label)}
                                        <span style={{color:`var(--c1)`,fontWeight: '600',fontSize: '16px'}}>*</span>
                                    </label>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        {input.type === "component" ? (
                                            <RenderComponent
                                                name={input.name}
                                                id={input.id}
                                                component={input.component}
                                                cultura={subCultura || "VE"}
                                            />
                                        ) : input.type === "select" ? (
                                            <RenderSelect {...input} t={t} />
                                        ) : input.type === "cedula" ? (
                                            <div className="wrap-cedula">
                                           
                                                <RenderSelect
                                                    name="nacionalidad"
                                                    id="nacionalidad"
                                                    defaultText=""
                                                    data={[
                                                        { id: "V", text: "V" },
                                                        { id: "E", text: "E" },
                                                    ]}
                                                    t={t}
                                                />
                                                <Field name={"cedula"}>
                                                    {({ field }) => (
                                                        <input
                                                            id={"cedula"}
                                                            type="text"
                                                            {...field}
                                                            onKeyDown={(
                                                                event
                                                            ) => {
                                                                if (
                                                                    (event.keyCode <
                                                                        48 ||
                                                                        event.keyCode >
                                                                            57) &&
                                                                    (event.keyCode <
                                                                        96 ||
                                                                        event.keyCode >
                                                                            105) &&
                                                                    event.keyCode !==
                                                                        190 &&
                                                                    event.keyCode !==
                                                                        110 &&
                                                                    event.keyCode !==
                                                                        8 &&
                                                                    event.keyCode !==
                                                                        9
                                                                ) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                        ) : input.id === "city" &&
                                          props.infoCom === "4" ? (
                                            <RenderSelectCity
                                                {...input}
                                                t={t}
                                            />
                                        ) : (
                                            <Field name={input.name}>
                                                {({ field }) => (
                                                    <input
                                                        id={input.id}
                                                        type="text"
                                                        {...field}
                                                    />
                                                )}
                                            </Field>
                                        )}
                                        <ErrorMessage
                                            name={input.name}
                                            component="span"
                                            className="errorText"
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        });
    };

    const validate = (values) => {
        const errors = {};
        Object.keys(values).forEach((key) => {
            if (!values[key]) errors[key] = t("required");
            if (values[key]) {
                switch (key) {
                    case "email":
                        if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                                values[key]
                            )
                        )
                            errors[key] = t("invalidEmail");
                        break;
                    case "repeatEmail":
                        if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                                values[key]
                            ) ||
                            values[key] !== values.email
                        )
                            errors[key] = t("invalidEmail");
                        break;
                    case "phoneNumber":
                        if (values[key].length < 9)
                            errors[key] = `${t("validationLength")} 9`;
                        break;
                    case "region":
                        if (values[key].length < 1)
                            errors[key] = t("selectRegion");
                        break;
                    case "firstName":
                    case "lastName":
                    case "address":
                    case "city":
                    case "zipCode":
                        if (values[key].length < 2)
                            errors[key] = `${t("validationLength")} 2`;
                        break;
                    default:
                        break;
                }
            }
        });
        validateForm(values, Object.keys(errors).length === 0);
        return errors;
    };
    let isLoged = SESSION && SESSION.Id && !Object.values(initialValues).includes(null) && !Object.values(initialValues).includes('');
    let [guestMode, setGuestMode] = useState( (!global?.LoginActive || localStorage.getItem("user-guest")) ?  true : false);
    /* useEffect(() => {
        Services.pay.updateSetupAuth(detalleRegistro?.Id)
    }, [guestMode]);
    useEffect(() => {
        getInitialValues();
    
    }, []); */
    //let t= resources[location.state.global.Idioma.toLowerCase()].translation;
    
    const formRef = useRef();
    useEffect(() => {
        if (formRef.current && validCounter > 0) {
            formRef.current.validateForm().then((validation) => {
                formRef.current.setTouched(validation);
                if (Object.keys(validation).length !== 0) {
                    window.scrollTo(0, formRef.current.offsetTop);
                    if((global?.LoginActive == true) && guestMode === false){
                        Swal.fire({
                            customClass: {
                                title: "titleCode",
                            },
                            showConfirmButton: false,
                            timer: 3000,
                            html:`<div class="modal fade modalAlert in" style="display: block" id="modalFailure" role="dialog">
                                <div
                                    class="modal-dialog modal-dialog-centered modal-md"
                                    role="document"
                                >
                                    <div class="modal-content">
                                        <div class="conten-expired clear-fl">
                                            <div class="top">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 16 18" fill="none">
                                                    <path fill="white" fill-rule="evenodd" clip-rule="evenodd" d="M8.0007 0.0419922C6.95088 0.0419922 5.94407 0.45903 5.20173 1.20136C4.4594 1.94369 4.04236 2.95051 4.04236 4.00033C4.04236 5.05014 4.4594 6.05696 5.20173 6.79929C5.94407 7.54162 6.95088 7.95866 8.0007 7.95866C9.05051 7.95866 10.0573 7.54162 10.7997 6.79929C11.542 6.05696 11.959 5.05014 11.959 4.00033C11.959 2.95051 11.542 1.94369 10.7997 1.20136C10.0573 0.45903 9.05051 0.0419922 8.0007 0.0419922ZM5.29236 4.00033C5.29236 3.28203 5.57771 2.59316 6.08562 2.08524C6.59353 1.57733 7.2824 1.29199 8.0007 1.29199C8.71899 1.29199 9.40787 1.57733 9.91578 2.08524C10.4237 2.59316 10.709 3.28203 10.709 4.00033C10.709 4.71862 10.4237 5.40749 9.91578 5.91541C9.40787 6.42332 8.71899 6.70866 8.0007 6.70866C7.2824 6.70866 6.59353 6.42332 6.08562 5.91541C5.57771 5.40749 5.29236 4.71862 5.29236 4.00033ZM8.0007 9.20866C6.0732 9.20866 4.29653 9.64699 2.9807 10.387C1.68403 11.117 0.709031 12.222 0.709031 13.5837V13.6687C0.708198 14.637 0.707364 15.852 1.7732 16.7203C2.29736 17.147 3.03153 17.4512 4.0232 17.6512C5.01653 17.8528 6.31237 17.9587 8.0007 17.9587C9.68903 17.9587 10.984 17.8528 11.979 17.6512C12.9707 17.4512 13.704 17.147 14.229 16.7203C15.2949 15.852 15.2932 14.637 15.2924 13.6687V13.5837C15.2924 12.222 14.3174 11.117 13.0215 10.387C11.7049 9.64699 9.92903 9.20866 8.0007 9.20866ZM1.95903 13.5837C1.95903 12.8745 2.47736 12.1045 3.5932 11.477C4.68986 10.8603 6.24653 10.4587 8.00153 10.4587C9.75487 10.4587 11.3115 10.8603 12.4082 11.477C13.5249 12.1045 14.0424 12.8745 14.0424 13.5837C14.0424 14.6737 14.009 15.287 13.439 15.7503C13.1307 16.002 12.614 16.2478 11.7307 16.4262C10.8499 16.6045 9.6457 16.7087 8.0007 16.7087C6.3557 16.7087 5.1507 16.6045 4.2707 16.4262C3.38736 16.2478 2.8707 16.002 2.56236 15.7512C1.99236 15.287 1.95903 14.6737 1.95903 13.5837Z" fill="#353B47"></path></svg>
                                            </div>
                                            <div class="content">
                                                <div class="title color-titulos">
                                                    ${t(`loginReq`)}
                                                </div>
                                                <div class="title color-titulos">
                                                    ${t(`loginReq2`)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>`
                        });
                    }
                }
            });
        }
    }, [validCounter]);
    useEffect(() => {
        if (SESSION.Id){
           formRef.current.setFieldValue("cedula", typeof parseInt(SESSION?.country_data?.Dni.substr(0,1)) === 'number' ? `${SESSION?.country_data?.Dni == '1'? 'v00000000': SESSION?.country_data?.Dni}` :SESSION?.country_data?.Dni?.substr(0,1) );
           formRef.current.setFieldValue("email", SESSION.Email);
           formRef.current.setFieldValue("firstName", SESSION.Name);
           formRef.current.setFieldValue("lastName", SESSION.LastName);
           formRef.current.setFieldValue("nacionalidad", typeof parseInt(SESSION?.country_data?.Dni.substr(0,1)) === 'number' ? 'v' : SESSION?.country_data?.Dni.substr(1));
           formRef.current.setFieldValue("phoneNumber", SESSION.Phone);
        }else{
           formRef.current.setFieldValue("cedula", '');
           formRef.current.setFieldValue("email", '');
           formRef.current.setFieldValue("firstName", '');
           formRef.current.setFieldValue("lastName", '');
           formRef.current.setFieldValue("nacionalidad", 'V');
           formRef.current.setFieldValue("phoneNumber", '');
        }
    }, [SESSION]);
    
    // console.log('SESSION',SESSION)
    return (<>
            {
                title && <div className="title-g sub-title-bold color-titulos">
                {
                    isLoged  ? t("guestInfo")
                    :(
                        guestMode === false ? 'Log in'
                        :  (global?.LoginActive== true ? t("guestInfo") : t("customerInfo") )                       
                    ) 
                }
                </div>
            }
            
            <div className="wrap-content">
                <Formik
                    onSubmit={(values, { setSubmitting }) => {
                        //alert(values);
                        //onSubmitForm(values, { setSubmitting });
                    }}
                    validate={validate}
                    initialValues={initialValues}
                    innerRef={formRef}
                >
                    {({ values, errors, touched }) => (
                        <div className="datos-cli">
                            {isLoged}
                            { 
                                <section className={`login_cont`}>
                                    {
                                        isLoged ? <div>
                                            <div>
                                                <div>
                                                    <b className="color-titulos " style={{fontSize: '20px',gap: '10px',alignItems: 'center'}}>
                                                        { `${SESSION.Name} ${SESSION.LastName}`} 
                                                        { SESSION.PhoneValidatedAt ? <i className="icon-verified" style={{fontSize:'24px'}}></i> :''}
                                                    </b>
                                                    <span>{ SESSION.Email}</span>
                                                </div>
                                                <label style={{display: 'flex',gap: '8px',color: `var(--c1)`, fontSize: '16px',cursor:'pointer'}}>
                                                    {t("changeAccount")}
                                                    <span style={{ display: "none" }}><ButtonLogin /></span>
                                                </label>
                                            </div>
                                            <div style={{borderRadius:`5px 5px 0px 0px`,padding: '15px',position:'relative'}}>
                                                <div style={{position:'absolute',top: '0',left: '0',width: '100%',height: '100%',zIndex: '0',borderRadius: '8px',overflow: `hidden`}}>
                                                    <div style={{background:`var(--c1)`,position: 'absolute',top: '0',left: '0',width: '100%',height: '100%',opacity: '0.2',zIndex: '0',opacity: '0.07'}}></div>
                                                    <Pattern/>
                                                </div>
                                                <div style={{zIndex: '1',display: 'flex',gap: '6px',flexDirection: 'column',fontWeight: '500'}}>
                                                    <div style={{color: '#999',display: `flex`,gap: `8px`,fontSize: `12px`, alignItems: `center`}}>
                                                        <DniIcon />
                                                        {t(`labelCedula`)}
                                                    </div>
                                                    <span style={{paddingLeft: `36px`,color: `#3e2524`,fontWeight: `500`}}>{`********${SESSION?.country_data?.Dni.substr(6,10)}`}</span>
                                                    <div style={{color: '#999',display: `flex`,gap: `8px`,fontSize: `12px`, alignItems: `center`}}>
                                                        <PhoneIcon />
                                                        {t(`labelPhoneNumber`)}
                                                    </div>
                                                    <label style={{paddingLeft: `36px`,color: `#3e2524`,fontWeight: `500`,display:`flex`,gap:`8px`,cursor:`${SESSION.PhoneValidatedAt ? undefined : 'pointer'}`}}>{`**** **${SESSION?.Phone.substr(7,10)}.`}

                                                        {
                                                            SESSION.PhoneValidatedAt ?
                                                            ''
                                                            :
                                                            <>
                                                                <Exclam />
                                                                <span style={{ display: "none" }}><ButtonLogin openDefault={'validate-phone'}/></span>
                                                            </>
                                                            
                                                        }
                                                    </label>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        :<>
                                            {
                                                (guestMode === false) ? <div>
                                                    <div>
                                                        {t(`loginDescription`)}
                                                        <br/>
                                                        <label className={'btn_login_false'} >{/*onClick={()=> Services.pay.updateSetupAuth(detalleRegistro?.Id)}*/''}
                                                            {t(`login`)}
                                                            <span style={{ display: "none" }}><ButtonLogin /></span>
                                                        </label>
                                                        <span onClick={()=>setGuestMode(true)} style={{lineHeight: '1',color:`var(--c1)`,cursor:`pointer`,fontWeight:`600` }}>
                                                            {t(`BuyAsAGuest`)}
                                                        </span>  
                                                    </div>
                                                    <div style={{borderRadius:`5px 5px 0px 0px`,padding: '15px',position: 'relative'}}>
                                                        <div style={{position:'absolute',top: '0',left: '0',width: '100%',height: '100%',zIndex: '0',borderRadius: '8px',overflow: `hidden`}}>
                                                            <div style={{background:`var(--c1)`,position: 'absolute',top: '0',left: '0',width: '100%',height: '100%',opacity: '0.2',zIndex: '0',opacity: '0.07'}}></div>
                                                            <Pattern/>
                                                        </div>
                                                        <div style={{zIndex: '1'}}>
                                                            <b>{t(`benefitsLogin`)}</b>
                                                            <div style={{whiteSpace: `pre`}}>
                                                                {t(`benefitsLoginDes`)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>:null
                                            }
                                        </>
                                    }
                                    <Form>
                                        <div
                                            className={(isLoged !== undefined) ? `` : `login_cont2`}
                                            style={{
                                                display: (isLoged !== undefined || guestMode === false) ? "none" : "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            {render(errors, touched)}
                                            {
                                                global?.LoginActive == true ?
                                                <span className="col col-sm-12 col-xs-12" style={{display: 'flex',gap: '8px'}}>
                                                    <label style={{color:`var(--c1)`,cursor:`pointer`,fontWeight:`600`,display: 'flex',gap: '8px'}}>
                                                        <UserIcon />
                                                        Login
                                                        <span style={{ display: "none" }}><ButtonLogin /></span>
                                                    </label>
                                                    <span style={{display: 'flex',gap: '8px',cursor:`pointer`}}>
                                                        {t(`loginOrCreate`)}
                                                        <b onClick={()=>beneficios(t)}>
                                                            {t(`seeBenefit`)}
                                                        </b>
                                                    </span>
                                                </span>
                                                :null
                                            }
                                            
                                        </div>
                                    </Form>
                                </section>
                            }
                        </div>
                    )}
                </Formik>
                {guestMode == true ? reservaGratis:null}
            </div>
        </>
    );
};

export default withTranslation()(CustomerForm);
