import React from "react";
import { useSelector } from "react-redux";

import {
    TMLoginProvider,
    getLocalStorage,
} from "@TicketMundo/login-tm-library";
import { setLogin } from "../../actions/global/";
import { useDispatch } from "react-redux";
const WrapperLogin = ({ children }) => {
    const global = useSelector((state) => state.global);
    const dispatch = useDispatch();
    function loginCallback(user) {
        const storageToken = getLocalStorage("token");
        const token = storageToken?.Value;
        if (token) {
            document.cookie = `token=${token} max-age=10; domain=ticketmundo.com`;
        }
       
        sessionStorage.setItem("session", JSON.stringify(user));
        dispatch(setLogin(user));
    }
    function logoutCallback() {
        sessionStorage.clear();
        dispatch(setLogin({}));
        // window.location.search = "";

    }
    return (
        <TMLoginProvider
            loginCallback={loginCallback}
            logoutCallback={logoutCallback}
            options={{
                ui: {
                  disabledLoginWithGoogle: false,
                  activeDarkMode: false,
                  primary_color: `var(--c1)`,
                  autoCloseDrawerOnGuest: true
                },
                appConfig: {
                  name: global.CompanyName ? global.CompanyName : "Tickmundo Venezuela",
                  company_id: global?.CompanyId ? global.CompanyId : 1,
                  country_id: global?.Country ? global.Country : undefined,
                  lang:global?.Idioma?.toLowerCase(),
                },
              }}
        >
            {children}
        </TMLoginProvider>
    );
};

export default WrapperLogin;
